
export const Config = {
    // REST_API : 'http://localhost:3003',
    // APP_TOKEN: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJjZjFmNjQ5NC01ZWExLTQ4NmUtOTc4My1hY2JhOTE4NWU1MzYiLCJhcHBJZCI6ImE5ZjE4NmJjOGZjYTRlYzk4ZjM5YTk4ODkxY2U2Y2FkIiwic2NvcGUiOiJhcHAiLCJpYXQiOjE3MzA3MTE2NzZ9.QouP67rMvdlOUu1T5Py6bXKn9SSeV6WKH6eczclH9xnhQwa0kjdYZ9ZwSlneqpBJ7elUDiuzyrYXMiuZHtDVBfjvGaqUno1IZiZD13iNRQAOUxRLfpvAbTVwIVlBduojkn5r-kgVzWiDDzGejpx99GXzDs6DYJpKKGjmagwwCeFJRTmgB21fVZ1sYfI8HSkmb7iB2DugGgJC5z9a4PERfAumxzFGwGVWZlwkE0-5ekVniNz0DwA3-5QrPiOb0YglI436N1J3JwKBmqkUUztXS_6fnz43rfaRH1d1Qy4igweyKo8vnm8PKEUScKGWWqp2oqHehKJkdWe2v1HrngJlmyQ8WV2uMo7_QSUdLiFce_cWErbccvBCRmEimBtmlPFnZ5O_crIeuLfY67z-0jhH5wDALL5SCWn6Zfp6QvGBoD7B9hrWegxqKPrIfJe6RsWxdSKfO8ErpCOlZdM-nedbLaDb5ROI954IyGAcNxzsjjRIpuHynMm10aI2f-Uk7Com',

    REST_API : 'https://api.appkey.io',
    APP_TOKEN: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJlN2Y2OWU3ZS01NWJiLTQzY2EtYTE4ZC02ODFkNGI0ZDI1MzciLCJhcHBJZCI6IjA2YTk3NmE4ZDExYjRkOTM5MjY4MTA0NzdmNWYyZDJlIiwic2NvcGUiOiJhcHAiLCJpYXQiOjE3MzMxNTA2OTd9.Dl2pEnxpcaLK8l_wor-XQBwb50TlC2MRCRyQVM3hAVn34wLxxdZOms-5xeVtIN7EIv_mP3BHN9vpTHu679HcsBZ5jWQNbfztzZX5h-FM0IanWHFPp6vihBoyWsRcWOwNHoPdgqNjeihkGfRWjXvpKQXRZv2qErpv4Lb2ferOu1rDkO4P_ghAmgflW2y9jm_UaXmd5Mt05WuVO3pFUyRjjdAgV23yTYYMSLeM3aZ4owOroZmAspNSFG7QWiotPK4itvb2qei1HASo9H5WYWjX3Ul09Sz5DROiPS-6wlWcIwLaYzCOa96O76O_qdFig0Qu9C-Xohn6ald1dCpSj97sPKlghOu-_k_5pvVRttQhpP0P1KbTPA-FMTQwRIhFmejAZQLrrKN2GAfCPgR3jn5zD8aAggCnSUVv2B_c1E-hdAuCuHJR2Q5btSjwnagZ4rVyzG8wy5bK9MkUslsnne8tcHkZQk8cOY3od5rVW2XZeH88h0R6kzm8snWqoqOCoFIV',
    GOOGLE_CLIENT_ID: '925050642020-eres6hjv7a456h781mloajavhnv8qb40.apps.googleusercontent.com',
    APPLE_BUNDLE_ID:'io.appkey.appdemo.web',
    APPLE_REDIRECT_URI:'https://demo.appkey.io'
    
   

}